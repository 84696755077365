/* Home page styling  */
h2{
    font-weight: 500 !important;
}

.nav-item a {
    font-weight: 600;
    font-size: 13px;
    color: #222;
}

.header-button{
background-color: transparent;
border: 1px solid #002c73;
font-size: 14px;
font-weight: 700;
padding: 8px 10px;
color: #002c73;
border-radius: 5px;
transition: all 0.5s;
}

.header-button:hover{
    color: #fff;
    background-color: #002c73;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)
}

/* Hero section start */
.hero-section{
    background: linear-gradient(to right, rgba(0, 44, 115, 1), rgba(255, 219, 88, 1)), url(../images/support.jpg);
    opacity: 0.95;
    background-blend-mode: overlay;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero-section h1{
    color: #fff;
    font-size: 39px;
    font-weight: 500;
    text-shadow: 1px 1px 5px #333;
    line-height: 3.2rem;

}

.hero-font{
    font-weight: 700;
}

.form{
    background-color: #fff;
    box-shadow: 5px 5px 20px #aaa;
    border-radius: 10px;
    padding: 10px 30px;
}

.form-btn{
background-color: #002c73;
border: none;
font-size: 14px;
font-weight: 700;
padding: 8px 10px;
color: #fff;
border-radius: 5px;
transition: all 0.5s;
width: 100%;
margin-top: 20px;
}

.form-btn:hover{
    box-shadow: 0px 3px 10px #aaa;
    background-color: #003da0;
}

.form h5{
    text-decoration: underline;
}

.form p{
    font-size: 12px;
    margin-top: 15px;
}

.note{
    font-weight: 800;
}

.form label{
    font-size: 13px !important;
    font-family: 'Montserrat', sans-serif;
    color: #222;
    font-weight: 500;
}

.hero-section .text{
  background-color:  rgba(0, 0, 0, 0.4);
  padding: 5%;
  margin-top: 30px;
  border-radius: 10px;
}

.text p{
    font-size: 14px !important;
    color: #eeeeee !important;
}

/* User Login started */
.user-login{
    margin-top: 10px;
}

.user-login p{
    color: #003da0;
    font-size: 12px;
    text-decoration: underline;
    
}
a{
    text-decoration: none !important;
}


/* New User login section start */
.login{
    background-image: url(../images/login-bg.png);
    position: fixed;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
 
}

.login h4,h6{
    font-weight: 600;
}
.login p{
    font-size: 12px;
    font-weight: 500;
}
.login-form{
    background-color: #ffffff;
    box-shadow: 1px 1px 3px #ddd;
    padding: 20px 30px;
    text-align: center;
}