@media only screen and (max-width: 786px) {
    .desktop{
        display: none !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 786px ) {
    .phone{
        display: none !important;
    }
}